/**
 * @file Meet your neighbors soho works
 * @author Alwyn Tan
 */

import React from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby'
import Layout from '../../components/layout'
import logo from '../../images/logo.png'
import ritzLA from '../../images/ritz-la.jpeg'
import SEO from '../../components/seo'
import ButtonV2 from '../../components/ButtonV2'

const FullScreenContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 30px;
`

const Container = styled.div`
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Logo = styled.img`
  height: 70px;
  object-fit: contain;
  filter: ${({ theme }) => (theme.isDarkMode ? 'invert()' : 'unset')};
`

const Section = styled.div`
  margin: 30px 0;

  h2 {
    position: relative;
    padding-bottom: 7px;
  }

  p {
    padding-left: 28px;
  }

  p:not(:last-child) {
    padding-bottom: 20px;
  }
`

const TitleImage = styled.img`
  height: 275px;
  width: 275px;
  max-width: 100%;
  object-fit: cover;
  border-radius: 10px;
`

const CoffeeChatInfo = () => {
  return (
    <Layout hideHeader>
      <SEO title="Solo | Meet Your Neighbor" />
      <FullScreenContainer>
        <Container>
          <Section>
            <Logo src={logo} />
          </Section>
          <h4 style={{ textAlign: 'center' }}>
            Welcome to the Ritz Carlton LA Live Residences Line. We’re
            connecting you with another resident in the building every Tuesday.
          </h4>
          <Section>
            <TitleImage src={ritzLA} />
          </Section>
          <Section>
            <h2>1. Register</h2>
            <p>
              Sign up for a Solo profile with your phone number and save ours in
              your phonebook ☎️
            </p>
            <h2>2. We’ll Call You</h2>
            <p>
              Subscribe and your phone will ring every week on Tuesday at 7pm PT
              and we’ll call you with one of your neighbors on the line. 🔊
              <br />
              <br />
              Not free when we call? No problem, just don’t pick up and we’ll
              call you again next week.
            </p>
            <h2>3. Rate the Call</h2>
            <p>
              Did you enjoy the chat? Send them a message and stay in touch on
              the app. 📩
            </p>
          </Section>
          <ButtonV2 onClick={() => navigate('/app/calls/ritzcarltonlalive')}>
            I'm In!
          </ButtonV2>
        </Container>
      </FullScreenContainer>
    </Layout>
  )
}

export default CoffeeChatInfo
